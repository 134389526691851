import { React, useState } from 'react'
import Logo from '../assets/Galeria/Iconos/LogoAfrica.png'
import { Link } from "react-router-dom";
import  Button  from '../components/Button';
import Bars3BottomRightIcon from '../components/icons/Bars'
import XmarkIcon from '../components/icons/Xmark'

const Header = () => {

const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className='shadown-md w-full fixed top-0 left-0 shadow-3xl'>
        <div className='md:px-12 py-3 px-7 md:flex justify-between items-center bg-white'>
            <div className='flex text-2xl cursor-pointer items-center gap-2'>
                <img src={Logo} alt='logoAfricahope' loading="lazy" className='w-14 h-14' />
                <span className='font-bold text-primary'>Africa Hope</span>
            </div>

            <div onClick={() => setIsMenuOpen(!isMenuOpen)} className='absolute right-8 top-6 cursor-pointer md:hidden'>
                {
                    isMenuOpen ? <XmarkIcon /> : <Bars3BottomRightIcon />
                }
            </div>
            {/**Navs Link */}
            <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-al duration-500 ease-in ${isMenuOpen ? 'top-16' : 'top-[-490px]'}`} >
            <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/inicio"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Inicio
                  </Link>
                </li>
                <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/somos"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Quienes Somos
                  </Link>
                </li>
                <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/equipo"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Nuestro Equipo
                  </Link>
                </li>
                <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/voluntariado"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Voluntariado
                  </Link>
                </li>
                <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/galeria"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Galeria
                  </Link>
                </li>
                <li className='font-semibold my-7 md:my-0 md:ml-8'>
                  <Link
                    to="/contacto"
                    className="text-primary hover:text-gray-300 font-bold"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    Contacto
                  </Link>
                </li>

                <div className='py-1 px2 md:ml-8 md:static' onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <Button />
                </div>

            </ul>
        </div>
    </div>
  )
}

export default Header