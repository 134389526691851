import React from "react";
import somosImg1 from "../assets/Galeria/somos.png";
import Cobertura from "./Cobertura";
import { motion } from "framer-motion";

const Somos = () => {
  return (
    <>
      <div className="container mx-auto p-20 m-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-4 mb-4 md:mb-0 pt-10">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              src={somosImg1}
              alt="Imagen-Somos"
              loading="lazy"
              className="object-cover lg:h-[700px] lg:w-[500px] rounded-lg cursor-pointer"
            ></motion.img>
          </div>
          <div className="md:w-1/2 md:pt-2">
            <motion.h1
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal"
            >
              Sobre Nosotros
            </motion.h1>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="text-xl text-tartiary text-justify mb-7"
            >
              Somos un grupo de voluntarios que busca generar soluciones
              concretas a las necesidades básicas en África, referente a
              educación, salud, autosuficiencia local (empoderamiento de la
              mujer), seguro social, alimentación, entre otros. Nuestra misión
              es construir un África y un Chile mejor, cooperando desde nuestras
              capacidades para mejorar la calidad de vida de las personas.
            </motion.p>
          </div>
        </div>
        <div className="my-4 py-0 md:px-2 px-10 max-w-screen-2xl mx-auto"></div>
      </div>
      <Cobertura />
    </>
  );
};

export default Somos;
