import React from "react";
import { motion } from "framer-motion";

const Banner = ({ banner, heading, subheading }) => {
  return (
    <div className="gradientBg rounded-xl rounded-br-[80px] md:p-9 px-4 py-9">
      <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-10">
        {/*banner image */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <img
            src={banner}
            alt="ImageBoy"
            loading="lazy"
            className="object-cover sm:h-[250px] sm:w-[250px] md:w-0 md:h-0 lg:h-[300px] lg:w-[500px] rounded-lg"
          ></img>
        </motion.div>
        {/*Banner contenido */}
        <div className="md:h-3/5">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            className="md:text-8xl text-4xl font-bold text-white mb-6 leading-relaxed"
          >
            {heading}
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            className="text-[#EBEBEB] text-2xl mb-8"
          >
            {subheading}
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
