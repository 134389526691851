import React from "react";

const NoFound = () => {
  return (
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-60 lg:px-8">
      <div class="text-center">
        <p class="text-5xl font-bold text-indigo-600">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p class="mt-6 text-bold leading-7 text-gray-600">
        Lo sentimos, no pudimos encontrar la página que estás buscando.
        </p>
      </div>
    </main>
  );
};

export default NoFound;
