import React from 'react'
import { Link } from "react-router-dom";

const Button = () => {
  return (
    <div className="">
              <Link to="/donación" >
              <button
                className="bg-secondary text-white py-2 px-4 transition-all duration-300 rounded
                   hover:text-orange hover:bg-indigo-700 font-semibold"
              >
                Donar Aquí
              </button>
              </Link>
            </div>
  )
}

export default Button
