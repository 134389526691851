import React from "react";
import LogoHope from "../assets/Galeria/Equipo/HopeCaty.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Pago = () => {
  return (
    <section className="bg-white dark:bg-gray-900 py-20">
      <div className="max-w-6xl px-6 py-10 mx-auto">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="mt-2 text-2xl font-semibold text-primary lg:text-3xl dark:text-white text-center"
        >
          Donación Para África Hope Chile
        </motion.h1>

        <main className="z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
          <div className="absolute w-full bg-white -z-10 md:h-96 rounded-2xl"></div>

          <div className="w-full p-6 bg-blue-600 md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-center">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: -50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="object-cover h-24 w-24 md:mx-6 rounded-full shadow-md md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl"
              src={LogoHope}
              alt="photo Caty"
            ></motion.img>

            <div className="mt-2 md:mx-6">
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, y: -50 },
                  visible: { opacity: 1, y: 0 },
                }}
                className="mt-4 text-xl text-justify text-primary md:text-xl"
              >
                “Te invitamos a participar de este proyecto, que busca hacer de
                nuestro planeta un mundo mejor. Al apoyar esta causa estarás
                cambiando la vida de un ser humano, entregando una mejor calidad
                de vida y progreso en los diferentes aspectos de su realidad.
                Somos un grupo de voluntarios que busca generar soluciones
                concretas a las necesidades básicas en África, referente a
                educación, salud, autosuficiencia local (empoderamiento de la
                mujer), seguro social, alimentación, entre otros. Nuestra misión
                es construir un África y un Chile mejor, cooperando desde
                nuestras capacidades para mejorar la calidad de vida de las
                personas. ”.
              </motion.p>

              <div className="flex items-center justify-center mt-10 md:justify-start">
                <button className="">
                  <Link to="/donar-aqui">
                    <button
                      className="bg-secondary text-white py-2 px-4 transition-all duration-300 rounded
                   hover:text-orange hover:bg-indigo-700 font-semibold"
                    >
                      Continuar para Donar
                    </button>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Pago;
