import React from "react";
import Fundadora2 from "../assets/Galeria/Equipo/fundadora2.png";
import { motion } from "framer-motion";

const Equipo = () => {
  return (
    <section className=" container mx-auto m-4 rounded-md p-6 text-center md:p-12 md:text-left">
      {/**Card de los datos de la fundadora */}
      <div className="mx-auto- grid md:grid-cols-2 pt-4 sm:m-5 sm:px-2 md:mx-2 ">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:flex-row justify-center items-center"
        >
          <div className=" bg-white shadow-2xl bg-clip-border rounded-xl w-80">
            <div className="mx-4 mt-4 overflow-hidden bg-white bg-clip-border rounded-xl h-30">
              <img
                src={Fundadora2}
                alt="fundadora"
                loading="lazy"
                className="object-cover"
              />
            </div>
            <div className="p-6 text-center">
              <span className="block mb-2 text-primary text-2xl antialiased font-semibold leading-snug tracking-normal">
                Catherine Toloza F.
              </span>
              <p className="block text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-indigo-800 to-indigo-600">
                Fundadora África Hope
              </p>
            </div>
            <div className="justify-center text-center px-6 p-8 pt-2 gap-2">
              <h3 className="">Licenciada en Contabilidad y Auditoría</h3>
              <h3>Gestión de Auditoría Interna</h3>
              <h3>Detección, e Investigación de Fraude</h3>
              <h3>Magister en Seguridad y Salud en el Trabajo</h3>
            </div>
          </div>
        </motion.div>

        {/**Card de testimonio de la fundadora */}

        <div className="flex justify-start content-start">
          <div className="max-w-5xl">
            <div className="m-4 block rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-800 dark:shadow-black/20">
              <div className="md:flex md:flex-row">
                <div className="md:ms-6">
                  <motion.h1
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    className="animate-fade-up animate-ease-linear animate-normal animate-fill-backwards text-balance md:text-3xl text-3xl sm:text-xl font-bold text-primary mb-5 py-2"
                  >
                    Como Inicio <span className="text-secondary">Todo</span>
                  </motion.h1>
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    className="mb-6 text-tartiary text-justify"
                  >
                    El 02 de Junio 2023 viajé a realizar un voluntariado a
                    Kenya, África; principalmente sirviendo en colegios,
                    orfanatos, y prestando apoyo a las familias vulnerables de
                    la localidad. Es por esto que en conjunto con otros
                    voluntarios se toma la iniciativa de instaurar este proyecto
                    que busca ayudar en diferentes aspectos a las personas que
                    residen allí, niños (as), adolescentes, mujeres que
                    requieren apoyo en autosuficiencia, y familias en
                    vulnerabilidad. Este proyecto nace con la intención de ser
                    un aporte en la disminución de brechas básicas como
                    alimentación, salud, educación. Creo firmemente que si
                    tenemos la voluntad y realizamos mínimas acciones, nuestro
                    mundo puede ser mejor.
                  </motion.p>
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    className="mb-2 text-xl font-semibold text-neutral-800 dark:text-neutral-200"
                  >
                    Catherine Toloza F.
                  </motion.p>
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    className="mb-0 font-semibold text-neutral-500 dark:text-neutral-400"
                  >
                    Fundadora África Hope.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Equipo;
