import React from 'react'
import Logo from '../assets/Galeria/Iconos/LogoAfrica.png'
import { FaInstagram} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-[#8d54bd] md:px-14 p-2 mx-auto text-white">
        <div className="my-5 flex flex-col md:flex-row gap-5">
            <div className="md:w-2/5 space-y-4">
            <a href="/" className="text-2xl font-semibold flex items-center space-x-2 text-primary">
                <img src={Logo} alt="logo" loading="lazy" className="w-14 h-14 inline-block items-center"/> <span className="text-white">Africa Hope</span>
                </a>
                <p className="md:w-1/2">Santiago - Chile</p>
            </div>
            <div className="md:w-1/2 flex flex-col md:flex-row flex-wrap justify-between gap-8 items-start">
                <div className="space-y-4 mt-5">
                    <h4 className="text-xl">Contenido</h4>
                    <ul className="space-y-3">
                        <a href="/inicio" className="block hover:text-gray-300">Inicio</a>
                        <a href="/somos" className="block hover:text-gray-300">Quienes Somos</a>
                        <a href="/equipo" className="block hover:text-gray-300">Nuestro Equipo</a>
                        <a href="/contacto" className="block hover:text-gray-300">Contacto</a>
                        <a href="/donación" className="block hover:text-gray-300">Dona Aqui</a>
                    </ul>
                </div>

                <div className="space-y-4 mt-5">
                    <h4 className="text-xl">Contacto</h4>
                    <ul className="space-y-3">
                        <p className="block hover:text-gray-300">Email: africahope.contacto@gmail.com</p>
                        <a href="https://www.instagram.com/africahope_chile/" rel='noreferrer' className="block hover:text-gray-300">Instagram: africahope_chile</a>
                    </ul>
                </div>
            </div>
        </div>
        <br />
        
        <div className='flex flex-col sm:flex-row gap-2 sm-items-center justify-between my-4'>
            <p>&copy; Africa Hope 2024 Todos los derechos reservados.</p>
            <div className='flex items-center space-x-5'>
            <a href='https://www.instagram.com/africahope_chile/' rel='noreferrer'
            className="block p-4 bg-secondary rounded-full text-3xl text-white border-2 border-transparent hover:border-white transition-colors duration-300"
            >
            <FaInstagram />
            </a>
            </div>
        </div>
    </div>
  )
}

export default Footer
