import React from 'react'
import banner from '../assets/Galeria/bannerInicio.png'
import Banner from '../shared/Banner'
import Noticias from './Noticias'

const Inicio = () => {
  return (
    <div className='md:px-12 text-justify p-4 max-w-screen-2xl mx-auto mt-20' id='home'>
        <Banner banner={banner} heading={"Africa Hope"} subheading="Te invitamos a participar de este proyecto, que busca hacer de nuestro planeta un mundo mejor.
        Al apoyar esta causa estarás cambiando la vida de un ser humano, entregando una mejor calidad de vida y progreso en los diferentes aspectos de su realidad."/>
        <div className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
        <Noticias/>
        </div>
    </div>
  )
}

export default Inicio