import React from "react";
import aboutImg1 from '../assets/Galeria/about.png';
import aboutImg2 from '../assets/Galeria/fotoVoluntario1.png';
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'


const Voluntariado = () => {
  return (
    <div className="md:px-14 max-w-s mx-auto space-y-10 p-7">
      <div className="flex flex-col md:flex-row justify-between items-center gap-8">
        <div className="md:w-3/5 pt-20">
          <div>
            <motion.img
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.5}}
            transition={{ delay: 0.3, duration: 0.5}}
            variants={{
              hidden: {opacity: 0, x: -50},
              visible:{opacity: 1, x: 0},}}
              src={aboutImg1}
              alt="experiences"
              loading="lazy"
              className="object-cover lg:h-[500px] lg:w-[800px] h-auto rounded-lg transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/50 cursor-pointer"
            ></motion.img>
          </div>
        </div>

        {/*Contenido de About */}

        <div className="md:w-1/2">
          <motion.h2 
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.5}}
          transition={{ delay: 0.4, duration: 0.5}}
          variants={{
            hidden: {opacity: 0, y: -50},
            visible:{opacity: 1, y: 0},}}
          className="md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal">
            Experiencias de Nuestro{" "}
            <span className="text-secondary">Voluntariado</span>
          </motion.h2>
          <motion.p 
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.5}}
          transition={{ delay: 0.5, duration: 0.5}}
          variants={{
            hidden: {opacity: 0, y: -50},
            visible:{opacity: 1, y: 0},}}
          className="text-tartiary text-justify  text-xl mb-7">
            Me inscribí en este programa con el propósito de contribuir
            entregando sonrisas poder crecer personalmente,demostrando que sin
            habilidades técnicas se puede ayudar a otros a través de la
            voluntad. Tan solo tomando atención del otro, escuchando,
            asistiendo, y apoyando le damos a ellos un mejor lugar para vivir.
            <br />
            <br />
            <i>Diego Fernández – Chile.</i>
            </motion.p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-8">
        <div className="md:w-1/2">
          <div>
            <motion.img
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.5}}
            transition={{ delay: 0.4, duration: 0.5}}
            variants={{
              hidden: {opacity: 0, x: 50},
              visible:{opacity: 1, x: 0},}}
              src={aboutImg2}
              alt="experiences"
              loading="lazy"
              className="object-cover h-auto lg:h-[500px] lg:w-[800px] rounded-lg transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/50 cursor-pointer"
            ></motion.img>
          </div>
        </div>
        <div className="md:w-3/5">
          <motion.h2 
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.5}}
          transition={{ delay: 0.3, duration: 0.5}}
          variants={{
            hidden: {opacity: 0, x: -50},
            visible:{opacity: 1, x: 0},}}
          className="md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal">
            Experiencias de Nuestro{" "}
            <span className="text-secondary">Voluntariado</span>
          </motion.h2>
          <motion.p 
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.5}}
          transition={{ delay: 0.4, duration: 0.5}}
          variants={{
            hidden: {opacity: 0, x: 50},
            visible:{opacity: 1, x: 0},}}
          className="text-tartiary text-justify  text-xl mb-7">
            Esta experiencia sin duda aportó una transformación a mi vida,
            conviviendo con una realidad distinta a la que tenemos los países
            desarrollados. Todos los voluntarios aportamos distintas
            habilidades, la forma como enfrentas una nueva cultura, idioma, y
            situaciones contribuyeron aún más a mi vida.
            <br />
            <br />
            <i>Iñigo Celorrio – España.</i>
          </motion.p>
          <a href="/donación">
            <button className="btnPrimary w-[200px] rounded-md font-medium my-6 py-3">
              Donar
            </button>
          </a>
          <div className="md:mt-10 max-w-s space-y-14">
            <Link
              className="md:text-3xl text-2xl font-bold text-primary mb-5 leading-normal"
              to="/programa"
            >
              Programas del Voluntariado
              <span className="text-red"> Aquí</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voluntariado;
