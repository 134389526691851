import React from 'react'
import CoberturaImg from '../assets/Galeria/africahope26.png'
import CoberturaImg2 from '../assets/Galeria/africahope27.png'
import Button from './Button'
import {motion} from 'framer-motion'

const Cobertura = () => {
  return (

    <div className="my-4 md:px-14 px-4 max-w-screen-2xl mx-auto">
    <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
      <div className="lg:w-1/2">
        <motion.h3
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.5}}
        transition={{ delay: 0.3, duration: 0.5}}
        variants={{
          hidden: {opacity: 0, x: -50},
          visible:{opacity: 1, x: 0},}}
        className="text-4xl text-primary font-bold mb-5">
          ÁFRICA HOPE CUBRE:
        </motion.h3>
        <motion.p 
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.5}}
        transition={{ delay: 0.4, duration: 0.5}}
        variants={{
          hidden: {opacity: 0, x: 50},
          visible:{opacity: 1, x: 0},}}
        className="text-xl text-primary mb-6">
          <span className="text-blue font-bold text-4xl text-justify">700</span> Niños y
          Jóvenes Escuela Gatanga Primary School Kenia, entre 4 a 14 años de
          edad.
        </motion.p>
        <motion.p 
         initial='hidden'
         whileInView='visible'
         viewport={{ once: true, amount: 0.5}}
         transition={{ delay: 0.5, duration: 0.5}}
         variants={{
           hidden: {opacity: 0, x: -50},
           visible:{opacity: 1, x: 0},}}
        className="text-xl text-primary mb-6">
          <span className="text-yellow-600 font-bold text-4xl text-justify">100</span> Familias
          en Vulnerabilidad.
        </motion.p>
        <motion.p 
         initial='hidden'
         whileInView='visible'
         viewport={{ once: true, amount: 0.5}}
         transition={{ delay: 0.6, duration: 0.5}}
         variants={{
           hidden: {opacity: 0, x: 50},
           visible:{opacity: 1, x: 0},}}
        className="text-xl text-primary mb-6">
          <span className="text-red font-bold text-4xl text-justify">50</span> Niños y
          Jóvenes de Orfanato de Gituru Agah Pay Mercy Children's Center,
          entre 2 a 18 años de edad.
        </motion.p>
        <div>
            <div className="grid grid-cols-2 gap-2 pt-10">
                <motion.img 
                 initial='hidden'
                 whileInView='visible'
                 viewport={{ once: true, amount: 0.5}}
                 transition={{ delay: 0.4, duration: 0.5}}
                 variants={{
                   hidden: {opacity: 0, x: -50},
                   visible:{opacity: 1, x: 0},}}
                src={CoberturaImg} alt='Cobertura1' loading="lazy" className=' object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer'>
              </motion.img>
            <div className="bottom-0 left-0">
                <motion.img 
                 initial='hidden'
                 whileInView='visible'
                 viewport={{ once: true, amount: 0.5}}
                 transition={{ delay: 0.4, duration: 0.5}}
                 variants={{
                   hidden: {opacity: 0, x: 50},
                   visible:{opacity: 1, x: 0},}}
                src={CoberturaImg2} alt='Corbertura2' loading="lazy" className='object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer'>
                </motion.img>
            </div>
        </div>
        </div>
      </div>
      {/* Caracteristicas Cards*/}
      <div className="w-full lg:w-3/4">
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-start md:gap-12">
          {/*Card 1 */}
          <div className="bg-blueCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl md:py-15 items-center flex justify-center hover:bg-white duration-300  cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $30.000 Terapia Ocupacional Para Niños con Movilidad Reducida
              </h5>
            </div>
          </div>
          {/*Card 2 */}
          <div className="bg-yellowCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center hover:bg-white duration-300 cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $20.000 Seguro Médico Anual de Una Familia
              </h5>
            </div>
          </div>
          {/*Card 3*/}
          <div className="bg-greenCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center hover:bg-white duration-300 cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $15.000 Educación Anual Para Un Niño
              </h5>
            </div>
          </div>
          {/*Card 4 */}
          <div className="bg-cyanCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center hover:bg-white duration-300 cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $10.000 Uniforme de un Niño
              </h5>
            </div>
          </div>
          {/*Card 5 */}
          <div className="bg-redCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center hover:bg-white duration-300 cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $10.000 Un saco de arroz o legumbres
              </h5>
            </div>
          </div>
          {/*Card 6 */}
          <div className="bg-skyCard bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center hover:bg-white duration-300 cursor-pointer">
            <div>
              <h5 className="text-2xl font-semibold text-primary px-5 text-center mt-5">
                $5.000 Zapatos Para Un Niño
              </h5>
            </div>
          </div>
        </div>
          <div className="flex flex-col md:flex-row-reverse justify-center items-center m-12">
         <Button />
          </div>
      </div>
    </div>
  </div>

  )
}

export default Cobertura