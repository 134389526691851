import React from "react";
import { motion } from "framer-motion";
import newsPhoto from "../assets/Galeria/fotoNoticias1.png";
import newsPhoto2 from "../assets/Galeria/fotoNoticias2.png";
import newsPhoto3 from "../assets/Galeria/fotoNoticias3.png";

import newsPhotoFutbol from "../assets/Equipo-femenino/Noticias-equipo-femenino.png";
import newsPhotoFutbol2 from "../assets/Equipo-femenino/Noticias-equipo-femenino2.png";
import newsPhotoFutbol3 from "../assets/Equipo-femenino/Noticias-equipo-femenino3.png";

const Noticias = () => {
  return (
    <div className="container mx-auto p-4">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="md:text-5xl text-3xl font-bold text-center text-primary mb-14"
      >
        <span className="text-secondary">Noticias</span> Africa Hope
      </motion.h1>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        <div>
          <img
            src={newsPhoto}
            alt="Imagen-noticias1"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
        <div>
          <img
            src={newsPhoto2}
            alt="Imagen-noticias2"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
        <div>
          <img
            src={newsPhoto3}
            alt="Imagen-noticias3"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
      </div>

      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-center text-primary mt-4 text-xl"
      >
        Orfanato de Gituru Agah Pay Mercy Children's Center recibe calzado y
        alimentación gracias a todas sus cooperaciones. Se le entrega sacos de
        comida, leche, harina, y todo lo necesario para algunos meses.
      </motion.p>

      {/**NOTICIAS EQUIPO FEMENINO */}
      <div className="my-20"></div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        <div>
          <img
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
            src={newsPhotoFutbol}
            loading="lazy"
            alt="Imagen-noticias-football"
          />
        </div>
        <div>
          <img
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
            src={newsPhotoFutbol2}
            loading="lazy"
            alt="Imagen-noticias-football"
          />
        </div>
        <div>
          <img
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
            src={newsPhotoFutbol3}
            loading="lazy"
            alt="Imagen-noticias-football"
          />
        </div>
      </div>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-center text-primary mt-4 text-xl"
      >
        Equipo de Fútbol Femenino “Gituru Queens”, recibe indumentaria para sus
        jugadoras.
      </motion.p>
    </div>
  );
};

export default Noticias;
