import React from "react";
import LogoHope from "../assets/Galeria/Equipo/HopeCaty.png";
import { motion } from "framer-motion";

const PagoFinal = () => {
  return (
    <section className="rounded-md p-6 mt-10 text-center shadow-lg md:p-12 md:text-left">
      <div className="flex justify-center">
        <div className="max-w-3xl">
          <div className="m-4 block rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-800 dark:shadow-black/20">
            <div className="md:flex md:flex-row">
              <div className="mx-auto mb-6 flex w-36 items-center justify-center md:mx-0 md:w-96 lg:mb-0">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  src={LogoHope}
                  className="object-cover rounded-full w-shadow-md dark:shadow-black/30"
                  alt="Fundadora"
                  loading="lazy"
                ></motion.img>
              </div>
              <div className="md:ms-8">
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  className="text-xl mb-6 text-primary"
                >
                  En post de dar cumplimiento a toda la normativa vigente en
                  Chile, y dar mayor alcance a nuestra causa estamos trabajando
                  en la implementación de plataformas de pago para ampliar
                  nuestra recaudación. Es por esto por lo que te agradecemos que
                  de manera momentánea, realices tus donaciones con la siguiente
                  información:
                </motion.p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-200">
                  NOMBRE: Catherine Yelena Toloza Fonseca
                </p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-400">
                  RUT: 17.361.625-2
                </p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-200">
                  BANCO: BCI
                </p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-400">
                  TIPO DE CUENTA: Corriente
                </p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-400">
                  N° CUENTA: 73230511
                </p>
                <p className="mb-2 font-semibold text-neutral-500 dark:text-neutral-400">
                  CORREO: africahope.contacto@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="mb-0 mt-0 pt-8 text-lg text-justify font-light leading-relaxed justify-center">
        Cabe señalar que en el asunto de la donación puedes indicar el aspecto
        al cual quieres que sea dirigida tu donación (salud, alimentación, o
        educación). Una vez realizada la transacción, nos contactaremos contigo
        para darte más información.
      </p>
      <footer className="block mb-5 pt-5 text-neutral-600 dark:text-neutral-400 justify-center text-center">
        -Agradecidos con tu apoyo con nuestra iniciativa
        <cite className="pl-2 justify-center ">África Hope Chile</cite>
      </footer>
    </section>
  );
};

export default PagoFinal;
