import React from 'react'
import { useForm } from "react-hook-form"
import emailjs from '@emailjs/browser';

const Contacto =() => {
/*EmailJS */
const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const templateParams = {
      name: data.name,
      email: data.email,
      fono: data.fono,
      asunto: data.asunto,
      message: data.message,
    };

    emailjs.send('service_4jh323t', 'template_xqitvk9', templateParams, 'ndDwmDBuxYCBNMn0q')
      .then((response) => {
        alert('Mensaje enviado:', response.status, response.text);
        reset();
      })
      .catch((error) => {
        alert('Error al enviar el mensaje:', error);
      });
  };

  return (
    <>
    <form className="text-gray-700 mt-20" onSubmit={(handleSubmit(onSubmit))}>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-primary">
            Contáctanos
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-primary">
            Si quieres ser parte de nuestra gran misión, puedes encontrarnos en nuestras redes sociales o en nuestra web.
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="">
                <label for="name" className="leading-7 text-sm text-gray-600">
                  Nombre
                </label>
                <input
                {... register('name', {required:true})}
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  //onChange={capturarInputs}
                  //value={data.name}
                />
                <span className='text-red'>{errors.name?.type === 'required' && "Este campo es obligatorio"}</span>
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="">
                <label
                  for="email"
                  className="leading-7 text-sm text-gray-600"
                >
                  Email
                </label>
                <input
                {... register('email', {required:true, pattern:/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i})}
                  type="email"
                  id="email"
                  name="email"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"

                />
                <span className='text-red'>{errors.email?.type === 'required' && "Este campo es obligatorio"}
                {errors.email?.type === 'pattern' && "El formato del correo no es correcto"}
                </span>
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="">
                <label
                  for="fono"
                  className="leading-7 text-sm text-gray-600"
                >
                  Fono
                </label>
                <input
                {... register('fono', {required:true, maxLength:12, pattern:/^([0-9]*)$/})}
                  type="tel"
                  id="fono"
                  name="fono"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                 
                />
                <span className='text-red'>{errors.fono?.type === 'required' && "Este campo es obligatorio"}
                {errors.fono?.type === 'pattern' && "Solo se permiten números en este campo"}
                </span>
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="">
                <label
                  for="asunto"
                  className="leading-7 text-sm text-gray-600"
                >
                  Asunto
                </label>
                <input
                {... register('asunto', {required:true})}
                  type="text"
                  id="asunto"
                  name="asunto"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                
                />
                <span className='text-red'>{errors.asunto?.type === 'required' && "Este campo es obligatorio"}</span>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="">
                <label
                  for="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Mensaje
                </label>
                <textarea
                {...register('message', {required: "Este campo es requerido" })}
                  id="message"
                  name="message"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  placeholder='Tu mensaje'
                ></textarea>
                {errors.message &&<span className='text-red'>{errors.message?.type === 'required' && "Este campo es obligatorio"}</span>}
              </div>
            </div>
            <div className="p-2 w-full">
              <button type='submit' className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Enviar</button>
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <p className="text-indigo-500">africahope.contacto@gmail.com</p>
              <p className="leading-normal my-5">
                <br />
                Santiago de Chile.
              </p>
              <span className="inline-flex">
                <p className="text-gray-500">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </p>
                <p className="ml-4 text-gray-500">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </p>
                <p className="ml-4 text-gray-500">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </p>
                <p className="ml-4 text-gray-500">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    </>
  );
}

export default Contacto