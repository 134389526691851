import React from "react";
import { motion } from "framer-motion";
import Fotogallery1 from "../assets/Escuela/africahope1.png";
import Fotogallery2 from "../assets/Escuela/africahope2.png";
import Fotogallery3 from "../assets/Escuela/africahope8.png";
import Fotogallery4 from "../assets/Escuela/africahope9.png";
import Fotogallery5 from "../assets/Escuela/africahope10.png";
import Fotogallery6 from "../assets/Escuela/africahope11.png";
import Fotogallery7 from "../assets/Escuela/africahope12.png";
import Fotogallery8 from "../assets/Escuela/africahope13.png";
import Fotogallery9 from "../assets/Escuela/africahope14.png";
import Fotogallery10 from "../assets/Escuela/africahope21.png";

import FotogalleryFami1 from "../assets/Galeria-Familia/africahope6.png";
import FotogalleryFami2 from "../assets/Galeria-Familia/africahope7.png";
import FotogalleryFami3 from "../assets/Galeria-Familia/africahope16.png";
import FotogalleryFami4 from "../assets/Galeria-Familia/africahope17.png";
import FotogalleryFami5 from "../assets/Galeria-Familia/africahope18.png";
import FotogalleryFami6 from "../assets/Galeria-Familia/africahope19.png";
import FotogalleryFami7 from "../assets/Galeria-Familia/africahope20.png";

import FotogalleryWoman1 from "../assets/Galeria-mujer/africahope4.png";
import FotogalleryWoman2 from "../assets/Galeria-mujer/africahope22.png";
import FotogalleryWoman3 from "../assets/Galeria-mujer/africahope23.png";
import FotogalleryWoman4 from "../assets/Galeria-mujer/africahope24.png";
import FotogalleryWoman5 from "../assets/Galeria-mujer/africahope25.png";

import FotogalleryDeporte1 from "../assets/Incentivo-Deportivo/Incentivo-deportivo1.png";
import FotogalleryDeporte2 from "../assets/Incentivo-Deportivo/Incentivo-deportivo2.png";
import FotogalleryDeporte3 from "../assets/Incentivo-Deportivo/Incentivo-deportivo3.png";

const Galeria = () => {
  return (
    <div className="my-20 py-10">
      {/**GALERIA DE ESCUELA */}

      <div className="p-5 md:p-10">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:text-5xl text-3xl sm:text-3xl font-bold text-primary mb-5 py-2"
        >
          Galeria fotos - <span className="text-secondary">Escuela</span>
        </motion.h1>
        <div className="colums-1 gap-5 lg:gap-8 sm:colums-2 lg:colums-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8">
          <img
            src={Fotogallery1}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery2}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery3}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery4}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery5}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery6}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery7}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery8}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery9}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={Fotogallery10}
            alt="Galeria-foto-escuela"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
      </div>

      {/**GALERIA DE FAMILIAS */}

      <div className="p-5 md:p-10">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:text-5xl text-3xl sm:text-3xl font-bold text-primary mb-5 py-2"
        >
          Galeria fotos - <span className="text-secondary">Familias</span>
        </motion.h1>
        <div className="colums-1 gap-5 lg:gap-8 sm:colums-2 lg:colums-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8">
          <img
            src={FotogalleryFami1}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami2}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami6}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami4}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami5}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami3}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryFami7}
            alt="Galeria-foto-familia"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
      </div>

      {/**GALERIA DE EMPODERAMIENTO MUJER */}

      <div className="p-5 md:p-10">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:text-5xl text-3xl sm:text-3xl font-bold text-primary mb-5 py-2"
        >
          Galeria fotos -{" "}
          <span className="text-secondary">Empoderamiento Mujer</span>
        </motion.h1>
        <div className="colums-1 gap-5 lg:gap-8 sm:colums-2 lg:colums-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8">
          <img
            src={FotogalleryWoman1}
            alt="Galeria-foto-mujer"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryWoman2}
            alt="Galeria-foto-mujer"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryWoman3}
            alt="Galeria-foto-mujer"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryWoman4}
            alt="Galeria-foto-mujer"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryWoman5}
            alt="Galeria-foto-mujer"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
      </div>

      {/**GALERIA DE INCENTIVO DEPORTIVO */}

      <div className="p-5 md:p-10">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:text-5xl text-3xl sm:text-3xl font-bold text-primary mb-5 py-2"
        >
          Galeria fotos -{" "}
          <span className="text-secondary">Incentivo Deportivo</span>
        </motion.h1>
        <div className="colums-1 gap-5 lg:gap-8 sm:colums-2 lg:colums-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8">
          <img
            src={FotogalleryDeporte1}
            alt="Galeria-foto-deportivo"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryDeporte2}
            alt="Galeria-foto-deportivo"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
          <img
            src={FotogalleryDeporte3}
            alt="Galeria-foto-deportivo"
            loading="lazy"
            className="object-cover object-center w-full h-96 max-w-full rounded-lg cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Galeria;
