import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Inicio from '../components/Inicio'
import Somos from '../components/Somos'
import Equipo from '../components/Equipo'
import Voluntariado from '../components/Voluntariado'
import Galeria from '../components/Galeria'
import Contacto from '../components/Contacto'
import Pago from '../components/Pago'
import PagoFinal from '../components/PagoFinal'
import Programa from '../components/Programa'
import NoFound from '../shared/NoFound'

const Rutas = () => {
  return (
    <div>
        <Routes>
            <Route path='/inicio' element={<Inicio />}></Route>
            <Route path='/somos' element={<Somos />}></Route>
            <Route path='/equipo' element={<Equipo />}></Route>
            <Route path='/voluntariado' element={<Voluntariado />}></Route>
            <Route path='/programa' element={<Programa />}></Route>
            <Route path='/galeria' element={<Galeria />}></Route>
            <Route path='/contacto' element={<Contacto />}></Route>
            <Route path='/donación' element={<Pago />}></Route>
            <Route path='/donar-aqui' element={<PagoFinal />}></Route>
            <Route path='/' element={<Navigate to="/inicio"/>}/>
            <Route path='*' element={<NoFound />}></Route>
            </Routes>
    </div>
  )
}

export default Rutas