import './App.css';
import Rutas from './router/Rutas';
import Footer from './shared/Footer'
import  Header from './components/Header';


function App() {
  return (
    <div className="bg-white  h-screen min-h-screen box-border md:box-content">
    <Header />
    <Rutas />
    <Footer />
  </div>
    
  );
}

export default App;
