import React from "react";
import ProgramImg from "../assets/Galeria-Familia/africahope19.png";
import Cards from "../components/Cards";
import { motion } from "framer-motion";

const Programa = () => {
  return (
    <>
      <article className="w-full py-16 px-4">
        <div className="max-w-[1240px] mx-auto- grid md:grid-cols-2 pt-20 ">
          <div>
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: -50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="object-cover mx-auto my-4 md:h-[500px] md:w-[400px] lg:h-[500px] lg:w-[400px] rounded-lg transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/50 cursor-pointer"
              src={ProgramImg}
              alt="ImagenPrograma"
              loading="lazy"
            ></motion.img>
          </div>
          <div className="flex flex-col justify-center">
            <motion.h1
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="text-balance md:text-5xl text-3xl sm:text-3xl font-bold text-primary mb-5 py-2"
            >
              Programa de <span className="text-secondary">Voluntariados</span>
            </motion.h1>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="text-tartiary text-justify text-xl mb-7"
            >
              África Hope incluye en su objetivo entregar acompañamiento a
              aquellas personas que desean realizar un voluntariado en los
              programas locales de África. El propósito general es aportar a un
              mundo mejor a través de nuestras capacidades y habilidades para
              ayudar a los demás es el desarrollo comunitario a través de
              experiencias de voluntariado a nivel internacional.
            </motion.p>
            <br />
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="text-tartiary text-justify text-xl mb-7"
            >
              Los programas te entregarán una nueva experiencia transformadora y
              un intercambio cultural, participando de los distintos programas
              que mantiene directamente con nuestra familia local.
            </motion.p>

            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.7, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="text-tartiary text-justify  text-xl mb-7"
            >
              Creemos en el voluntariado como un medio para impactar
              positivamente con pequeñas acciones en comunidades vulnerables, y
              de crecimiento personal para todos nuestros voluntarios y
              voluntarias. En marzo de 2023, lanzamos nuestra ONG, África Hope,
              con el fin de construir puentes entre la acción humanitaria y
              cubrir las necesidades concretas de nuestros hermanos.
            </motion.p>
          </div>
        </div>
      </article>

      {/**CARDS */}
      <Cards />
    </>
  );
};

export default Programa;
